.channel-list__container {
  display: flex;
  /* height: 800px; */
  height: 100%;
  box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
}

.channel-list__sidebar {
  width: 72px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary-color);
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.25);
  /* border-top-left-radius: 16px; */
  /* border-bottom-left-radius: 16px; */
}

.channel-list__sidebar__icon1 {
  width: 44px;
  height: 44px;
  margin: 14px;
  background: linear-gradient(150.64deg, rgba(0, 0, 0, 0.1) 12.73%, rgba(0, 0, 0, 0) 89.32%), #ffffff;
  border-radius: 9999px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.33);
}

.channel-list__list__wrapper {
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  width: 240px;
}

.icon1__inner {
  font-family: Helvetica Neue, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-list__sidebar__icon2 {
  width: 44px;
  height: 44px;
  margin-left: 14px;
  background: linear-gradient(0deg, #000000, #000000);
  opacity: 50%;
  border-radius: 9999px;
}

.channel-list__sidebar__icon3 {
  width: 44px;
  height: 44px;
  margin: 14px;
  background: linear-gradient(150.64deg, rgba(0, 0, 0, 0.1) 12.73%, rgba(0, 0, 0, 0) 89.32%), #ffffff;
  border-radius: 9999px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.33);
}



.icon3__inner {
  font-family: Helvetica Neue, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.channel-list__sidebar__icon4 {
  width: 44px;
  height: 44px;
  margin-left: 14px;
  background: linear-gradient(0deg, #000000, #000000);
  opacity: 0.5;
  border-radius: 9999px;
}

.icon4__inner {
  font-family: Helvetica Neue, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.channel-list__header {
  padding-left: 16px;
  background: var(--primary-color);
  height: 62px;
}

.channel-list__header__text {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
