:root {
  --primary-color: #4e1d9d;
  --primary-color-alpha: #4e1d9d1a;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.home{
width:1200px;
padding-left:10px;
}

#root {
  height: 100%;
}

.app__wrapper {
  display: flex;
  flex: 1;
  /* height: 800px; */
  height: 100%;
  /* border-radius: 16px; */
  box-shadow: rgba(0, 0, 0, 0.33) 0px 1px 4px 0px;
}

.str-chat-channel-list {
  height: fit-content;
  margin-bottom: 8px;
}

.str-chat-channel {
  height: 100%;
}

.str-chat__load-more-button {
  display: none;
}

.str-chat__input-footer {
  display: none;
}

.str-chat__date-separator {
  margin: 16px 24px;
}

.str-chat__message-notification {
  background: var(--primary-color);
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .str-chat-channel-list.messaging,
  .str-chat-channel-list.team,
  .str-chat-channel-list.commerce {
    position: unset;
    left: unset;
    top: unset;
    z-index: unset;
    min-height: unset;
    overflow-y: unset;
    box-shadow: unset;
    transition: unset;
  }
}
